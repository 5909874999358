<template>
  <div class="add-product-main" v-loading="isLoading">
    <div class="data-content">
      <div class="title-text">产品信息</div>
      <div class="form-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormData" label-width="135px" class="demo-ruleForm">
          <el-form-item label="产品类型" prop="type">
            <el-radio-group v-model="ruleForm.type">
              <el-radio :label="1">软件</el-radio>
              <el-radio :label="2">硬件</el-radio>
              <el-radio :label="3">其他</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="产品名称" prop="name">
            <el-input v-model="ruleForm.name" :placeholder="rules.name[0].message" style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="产品分类" prop="subCate_id">
            <el-cascader v-model="ruleForm.subCate_id" :options="proClass" :props="{value: 'id', label: 'name'}"
                         placeholder="请选择产品分类"></el-cascader>
          </el-form-item>
          <el-form-item label="产品主图" prop="product_img">
            <el-upload
                :action="uploadData.url"
                :headers="uploadData.headers"
                :name="uploadData.name"
                :accept="uploadData.accept"
                list-type="picture-card"
                :file-list="fileList"
                :on-success="loadSuccess"
                :on-preview="handlePictureCardPreview"
                :before-upload="handleBeforeUpload"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
          <el-form-item label="适用地区">
            <el-cascader v-model="ruleForm.area_id" :options="options" placeholder="请选择适用地区" collapse-tags
                         :props="{value: 'id', label: 'name', multiple:true }"></el-cascader>
          </el-form-item>
          <el-form-item label="标签">
            <input class="label-input" :maxlength="6" v-model="labelText" type="text"
                   :placeholder="rules.tags[0].message"/>
            <p class="add-btn" @click.stop="addTabel">添加</p>
            <div class="label-content flex flex-col-center" v-if="ruleForm.tags.length>0">
              <span class="label-title">已添加</span>
              <el-tag v-for="tag in ruleForm.tags" :key="tag" closable @close="labelClose(tag)">{{ tag }}</el-tag>
            </div>
          </el-form-item>
          <el-form-item label="产品价格" prop="price">
            <el-input v-model="ruleForm.price" :placeholder="rules.price[0].message" style="width: 240px;"></el-input>
            <span class="price-dw">元</span>
          </el-form-item>
          <el-form-item label="收费方式" prop="charge_method">
            <!-- <el-input v-model="ruleForm.charge_method" :placeholder="rules.charge_method[0].message" style="width: 240px;"></el-input> -->
            <el-radio-group v-model="ruleForm.charge_method">
              <el-radio :label="item.id" v-for="(item, index) in chargeList" :key="index">{{ item.dict_name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="收费说明" prop="charge_desc">
            <el-input v-model="ruleForm.charge_desc" :placeholder="rules.charge_desc[0].message" maxlength="100" show-word-limit
                      style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="是否关联外部URL" prop="is_outside" class="from-item">
            <el-radio-group v-model="ruleForm.is_outside" @input="updateRadio">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="2">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="关联URL" prop="outside_url" v-show="ruleForm.is_outside==1">
            <el-input v-model="ruleForm.outside_url" :placeholder="rules.outside_url[0].message" style="width: 400px;"></el-input>
          </el-form-item>
          <el-form-item label="产品特点" prop="feature">
            <el-input type="textarea" v-model="ruleForm.feature" :placeholder="rules.feature[0].message" maxlength="200"
                      show-word-limit style="width: 1000px;"></el-input>
          </el-form-item>
          <el-form-item label="产品介绍" prop="introduce" style="height: 450px;" v-show="ruleForm.is_outside!=1">
            <el-upload
                v-show="false"
                class="avatar-uploader"
                :action="uploadUrl"
                :show-file-list="false"
                :accept="uploadData.accept"
                :on-success="uploadSuccess"
                name="image"/>
            <quill-editor v-model="ruleForm.introduce" ref="QuillEditor" :options="editorOption" style="height: 400px;"></quill-editor>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('ruleFormData')">确定</el-button>
            <el-button @click="exitClick">返回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import address from "../questionnaire/components/address.js"
import uploadImg from "@/utils/uploadImg.js"
const toolbarOptions = [

  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
  ['blockquote', 'code-block'], // 引用  代码块
  [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标
  [{ indent: '-1' }, { indent: '+1' }], // 缩进
  [{ direction: 'rtl' }], // 文本方向
  ['link', 'image', 'video'], // 链接、图片、视频
  [{ align: [] }], // 添加居中按钮
  [{ color: [] }], // 文字颜色按钮

]
export default {
  data() {
    return {
      ruleForm: {
        type: 1,//产品类型
        name: '',//产品名称
        cate_id: [],//产品分类
        subCate_id: [],//产品分类上传
        product_img: [],//产品主图
        area_id: [],//适用地区
        tags: [],//标签
        tagsSub: '',//上传时标签
        price: '',//产品价格
        charge_method: 1,//收费方式
        charge_desc: '',//收费说明
        is_outside: 2,//是否关联外部URL
        outside_url: '',//关联的URL
        feature: '',//产品特点
        introduce: '',//产品介绍
      },
      rules: {
        type: [{required: true, message: '请选择活动资源', trigger: 'change'}],
        name: [{required: true, message: '请填写产品名称', trigger: 'blur'}],
        subCate_id: [{required: true, message: '请填选择产品分类', trigger: 'blur'}],
        product_img: [{required: true, message: '请添加图片'}],
        area_id: [{required: true, message: '请选择地区', trigger: 'blur'}],
        tags: [
            {required: true, message: '请填写标签名称（不超过6个字）', trigger: 'blur'}
        ],
        price: [{required: true, message: '请填写产品价格', trigger: 'blur'}],
        charge_method: [{required: true, message: '如一次性付费、按功能付费、按年付费', trigger: 'change'}],
        charge_desc: [{required: false, message: '如有其他收费说明，可在此补充，将在产品详情页展示', trigger: 'blur'}],
        is_outside: [{required: true, message: '请选择关联', trigger: 'change'}],
        outside_url: [{required: true, message: '请填写URL，将作为产品详情页', trigger: 'blur'}],
        feature: [{required: true, message: '请填写产品简要描述，将用于产品推荐时展示', trigger: 'blur'}],
        introduce: [{required: true, message: '请填写产品介绍', trigger: 'blur'}],

      },
      uploadData: {url: ''},//上传图片参数
      proClass: [],//产品分类所有数据
      options: [],//产品分类
      dialogImageUrl: '',//产品主图
      dialogVisible: false,//产品主图
      fileList: [],//产品图默认
      chargeList: [],//收费方式
      labelText: '',//填写的标签名称
      isLoading: false,//是否正在加载
      editorOption: {

        placeholder: '请在这里输入',

        modules: {

          history: {

            delay: 1000,

            maxStack: 50,

            userOnly: false

          },

          toolbar: {

            container: toolbarOptions,

            handlers: {

              image: function(value) {

                if (value) {

                  console.log(document.querySelector('.avatar-uploader'))

                  // 调用element的图片上传组件

                  document.querySelector('.avatar-uploader input').click()

                } else {

                  this.quill.format('image', false)

                }

              }

            }

          }

        }

      }
    }
  },
  computed: {

    //获取后端的上传接口地址

    uploadUrl() {

      return '/file/upload_single_image'

    }

  },
  mounted() {
    let page = this.$route.query;
    this.uploadData = uploadImg;
    this.initData();
    this.getAddressData();
    if (page.type == 'edit') this.getInitData(page.id);
  },
  methods: {
    uploadSuccess(res) {

      // 获取富文本组件实例

      const quill = this.$refs.QuillEditor.quill

      // 如果上传成功

      if (res) {

        // 获取光标所在位置
        console.log(res,11)
        const length = quill.getSelection().index

        // 插入图片，res为服务器返回的图片链接地址
        // let imagePath = 'http://test_ylpt.lzzcloud.net/' + res.data.image_path
        let imagePath =  res.data.image_url
        quill.insertEmbed(length, 'image', imagePath)

        // 调整光标到最后
        quill.setSelection(length + 1)

      } else {

        // 提示信息，需引入Message

        this.$message.error('图片插入失败！')

      }

    },

    // 监听编辑器内容

    onEditorChange({ quill, html, text }) {

      this.content = html

      this.$emit('input', html)
    },
    initData() {
      this.$api.cateList({status: 1}).then(res => {
        this.proClass = res.data;
      })
      this.$api.dictionary_option_list().then(res => {
        this.chargeList = res.data;
        this.ruleForm.charge_method = res.data[0].id;
      })
    },
    //确定
    onSubmit(formName) {
      this.isLoading = true;
      this.ruleForm.tagsSub = this.ruleForm.tags.join(',');
      this.ruleForm.cate_id = this.ruleForm.subCate_id[this.ruleForm.subCate_id.length - 1];
      // console.log('上传：', this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
			if (this.ruleForm.is_outside == 1) {
				this.ruleForm.introduce = '';
			} else {
				this.ruleForm.outside_url = '';
			}
          this.$api.productSave(this.ruleForm).then(res => {
            this.isLoading = false;
            this.$message({type: 'success', message: '添加成功！'});
            this.$router.push({path: '/productHome'});
          }).catch(err=>{
			  this.isLoading = false;
		  })
        } else {
          console.log('error submit!!');
          this.isLoading = false;
          return false;
        }
      });
    },
    //编辑产品时获取数据
    getInitData(id) {
      this.$api.productInfo(id).then(res => {
        Object.assign(this.ruleForm, res.data);
        for (let i = 0; i < this.ruleForm.product_img.length; i++) {
          this.fileList.push({name: '0' + i + '1.jpg', url: this.ruleForm.product_img[i]});
        }
        this.ruleForm.subCate_id = res.data.cate_node_id;
        if (res.data.is_outside == 1) {
			this.rules.introduce[0].required = false;
			this.rules.outside_url[0].required = true;
        } else {
			this.rules.introduce[0].required = true;
			this.rules.outside_url[0].required = false;
        }

      })
    },
    //关联切换
    updateRadio(e) {
		if (e == 1) {
			this.rules.introduce[0].required = false;
			this.rules.outside_url[0].required = true;
		} else {
			this.rules.introduce[0].required = true;
			this.rules.outside_url[0].required = false;
		}
    },
    //获取地区数据
    getAddressData() {
      this.$api.areaTreeList().then(res => {
        this.options = res.data;
        this.ruleForm.area_id = this.getLastAreaIdArr(this.options);
      })
    },
    // 获取数组树结构中的[[一级id, 二级id, 三级id, ..., 最后一级id],[一级id, 二级id, 三级id, ..., 最后一级id]]数组
    getLastAreaIdArr(data) {
      let arr = [];

      function processChildren(children, parentIdArr) {
        children.forEach(child => {
          let newIdArr = parentIdArr.concat(child.id);
          if (child.children && child.children.length > 0) {
            processChildren(child.children, newIdArr);
          } else {
            arr.push(newIdArr);
          }
        });
      }

      data.forEach(item => {
        let parentIdArr = [item.id];
        if (item.children && item.children.length > 0) {
          processChildren(item.children, parentIdArr);
        }
      });

      return arr;
    },
    //添加标签
    addTabel() {
      if(!this.labelText) {
        // 可以选择提示用户已达到最大标签数量或者其他操作
        this.$message({
          type: 'warning',
          message: '请填写标签名称'
        });
        return;
      }
      if (this.ruleForm.tags.length < 4) {
        this.ruleForm.tags.push(this.labelText);
        this.labelText = '';
      } else {
        // 可以选择提示用户已达到最大标签数量或者其他操作
        this.$message({
          type: 'warning',
          message: '单个商品最多可添加4个标签'
        });
      }
    },
    //删除标签
    labelClose(e) {
      this.ruleForm.tags.splice(this.ruleForm.tags.indexOf(e), 1);
    },
    //上传图片成功
    loadSuccess(e, file, fileList) {
      if (e.status !== 200) {
        this.$message.error(e.msg);
      } else {
        this.fileList = fileList;
        this.ruleForm.product_img = [];
        for (let i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].response) {
            this.ruleForm.product_img.push(this.fileList[i].response.data.image_url);
          } else {
            this.ruleForm.product_img.push(this.fileList[i].url);
          }
        }
      }
    },
    //预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleBeforeUpload(file) {
      const maxSize = 4 * 1024 * 1024; // 10MB，根据你的需求设置最大文件大小
      if (file.size > maxSize) {
        this.$message.error('文件大小不能超过4MB');
        return false;
      }
    },
    //删除图片
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      this.ruleForm.product_img = [];
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].response) {
          this.ruleForm.product_img.push(this.fileList[i].response.data.image_url);
        } else {
          this.ruleForm.product_img.push(this.fileList[i].url);
        }
      }
    },
    //返回
    exitClick() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.add-product-main {
  padding: 18px;
  width: 100%;
  box-sizing: border-box;

  .data-content {
    background-color: #fff;
    padding: 30px 0;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;

    .title-text {
      font-size: 18px;
      color: #242D33;
      position: relative;
      margin-left: 30px;

      &:before {
        position: absolute;
        content: '';
        left: -30px;
        top: 4px;
        height: 20px;
        width: 4px;
        background: #3984F5;
      }

      &::after {
        position: absolute;
        content: '';
        left: 82px;
        top: 50%;
        width: 100%;
        height: 1px;
        background: #EDF1F4;
      }
    }

    .form-content {
      padding: 40px;

      .el-cascader {
        width: 400px;
      }

      .label-input {
        width: 320px;
        height: 34px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #CEDAE0;
        line-height: 34px;
        padding: 0 12px;
        box-sizing: border-box;
      }

      .add-btn {
        display: inline-block;
        margin-left: 10px;
        width: 70px;
        height: 34px;
        background: #3984F5;
        border-radius: 4px;
        line-height: 34px;
        color: #fff;
        font-size: 12px;
        text-align: center;

        &:hover {
          cursor: pointer;
          opacity: .8;
        }
      }

      .label-content {
        margin-top: 6px;

        .label-title {
          font-size: 12px;
          color: #242D33;
          margin-right: 14px;
        }

        .el-tag + .el-tag {
          margin-left: 10px;
        }
      }

      .price-dw {
        font-size: 12px;
        color: #242D33;
        margin-left: 12px;
      }

      .from-item {
        .el-form-item__label {
          width: 135px !important;
        }
      }
    }

  }

}

::v-deep(.el-input__inner) {
  height: 34px;
  line-height: 34px;
  font-size: 12px;
}

// ::v-deep(.el-form-item__label){width: 135px !important;}

</style>