import * as auth from "./auth.js"


/**
 * 上传单个图片的参数
 */
export default {
	url: (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/api')+'file/upload_single_image',
	headers: {
		'Authorization': auth.getToken()?auth.getToken():''
	},
	multiple: false,//是否支持多选
	name: 'image',
	accept: "image/png, image/jpeg",
}